<template lang="html">
  <div class="row">
    <div class="col-lg-3 col-6" v-if="data">
      <!-- small card -->
      <div class="small-box bg-info">
        <div class="inner">
          <h3>{{((data.libSizeInfo.fileSize+data.libSizeInfo.privateSize)/(data.libSizeInfo.libBaseSize+data.libSizeInfo.awardSize)*100).toFixed(2)}}<sup style="font-size: 20px">%</sup></h3>

          <p>素材库</p>
        </div>
        <div class="icon">
          <i class="fas fa-photo-video"></i>
        </div>
        <router-link :to="{ name: 'LibList'}" class="small-box-footer">
          管理 <i class="fas fa-arrow-circle-right"></i>
        </router-link>
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-6" v-if="data">
      <!-- small card -->
      <div class="small-box bg-success">
        <div class="inner">
          <h3>{{data.panoCount}}</h3>

          <p>全景</p>
        </div>
        <div class="icon">
          <i class="fa fa-image"></i>
        </div>
        <router-link :to="{ name: 'PanoList'}" class="small-box-footer">
          管理 <i class="fas fa-arrow-circle-right"></i>
        </router-link>
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-6" v-if="data">
      <!-- small card -->
      <div class="small-box bg-secondary">
        <div class="inner">
          <h3>{{data.blogCount}}</h3>

          <p>博文</p>
        </div>
        <div class="icon">
          <i class="fa fa-file-image"></i>
        </div>
        <router-link :to="{ name: 'BlogList'}" class="small-box-footer">
          管理 <i class="fas fa-arrow-circle-right"></i>
        </router-link>
      </div>
    </div>
    <!-- ./col -->
    <div class="col-lg-3 col-6" v-if="data">
      <!-- small card -->
      <div class="small-box bg-danger">
        <div class="inner">
          <h3>{{data.tourCount}}</h3>

          <p>漫游</p>
        </div>
        <div class="icon">
          <i class="fa fa-globe"></i>
        </div>
        <router-link :to="{ name: 'TourList'}" class="small-box-footer">
          管理  <i class="fas fa-arrow-circle-right"></i>
        </router-link>
      </div>
    </div>
    <!-- ./col -->
    <footer class="main-footer text-sm">
      <div class="float-right d-none d-sm-block">
        <b>Version</b> {{config.version}}
      </div>
      <strong>Copyright &copy; 2015-2022 <a href="https://panorover.com">PanoRover.com</a>.</strong> All rights reserved. <span class="d-inline d-sm-none"><b>Version</b> {{config.version}}</span>
    </footer>
  </div>
  <!-- /.row -->
</template>

<script>
  import config from '@/../package.json'
    export default {
        data() {
            return {
                data: null,
                config:config,
            }
        },
        created() {
        },
        mounted() {
            document.getElementsByTagName("body")[0].classList.add("layout-footer-fixed")
            this.loadData()
        },
        destroyed() {
            document.getElementsByTagName("body")[0].classList.remove("layout-footer-fixed")
        },
        computed: {
        },
        methods: {
            loadData() {
                var _this = this;
                this.$axios.get('/Api/My/Home').then(function (res) {
                    _this.data = res.data
                })
            },
        },
        components: {
        }
    }
</script>

<style lang="css">
</style>
